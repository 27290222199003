var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "mw-580px mx-auto"
  }, [_vm.tab != 'result' ? _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-btn-toggle', {
    staticClass: "primary",
    attrs: {
      "mandatory": "",
      "group": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-50",
    attrs: {
      "value": _vm.USER_TYPES.PERSON.value
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("개인회원")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-50",
    attrs: {
      "value": _vm.USER_TYPES.COMPANY.value
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("기업회원")])], 1)], 1) : _vm._e(), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "form"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs text-right pb-16px pb-md-20px"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    on: {
      "certification": function (value) {
        return _vm.form._certification = value;
      }
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.form.password,
      "placeholder": "동일한 비밀번호를 재입력하세요."
    }
  })], 1), _c('template', {
    slot: "주소"
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "btnAttrs": {
        large: true,
        color: 'primary'
      }
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 2), _c('div', {
    staticClass: "pt-30px pt-md-40px"
  }, [_c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_tertiary, _vm.$attrs, {
    loading: _vm.loading
  }), false), [_vm._v("취소하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    on: {
      "click": _vm.join
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs, {
    loading: _vm.loading,
    disabled: _vm.disabled
  }), false), [_vm._v("회원가입하기")])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "result"
    }
  }, [_c('div', {
    staticClass: "text-center pt-30px pb-20px"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('icon-check')], 1), _c('tit-wrap-subtitle', {
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("회원가입이 완료되었습니다.")]), _c('template', {
    slot: "txt"
  }, [_vm._v("대시보드에서 추가 정보를 입력해 주세요.")])], 2), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/mypage"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("MY대시보드로 이동")])], 1)], 1)], 1)], 1)])], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }